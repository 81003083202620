<template>
    <div class="p-grid">
        <Toast />
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4>
                    <strong>검색</strong>
                </h4>
                <div class="p-formgrid p-grid p-mb-3">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="Reported">아이디</label>
                        <InputText v-model="query.email" type="text" placeholder="이메일 계정으로 검색" />
                    </div>

                    <div class="p-field p-col-12 p-md-3">
                        <label for="Chatroomname">품의번호</label>
                        <InputText v-model="query.product_no" onkeypress="return /[0-9]/i.test(event.key)" type="text" placeholder="품의번호로 검색" />
                    </div>
                </div>

                <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                    <div class="p-mb-4 p-mb-lg-0">
                        <!-- <Button :label="$t('button.today')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="today"></Button>
                      <Button :label="$t('button.lastWeek')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastweek"></Button>
                      <Button :label="$t('button.lastMonth')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastmonth"></Button>
                      <Button :label="$t('button.last6Months')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastsixmonth"></Button> -->
                        <!-- <Button :label="$t('button.lastYear')" icon="pi pi-calendar" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="lastyear"></Button> -->
                    </div>
                    <div>
                        <Button label="검색" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2" @click="search"></Button>
                        <Button label="초기화" icon="pi pi-replay" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="reset"></Button>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex p-jc-between p-mb-2">
                    <div>
                        <h5>계정 승인 요청 목록</h5>
                    </div>
                    <div>
                        <!-- <Button label="Primary" class="p-mr-2 p-mb-2" @click="excelUpload"><i class="pi pi-download p-mr-2"></i>{{ $t('Upload Excel') }}</Button> -->
                        <!-- <Button target="_blank" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="popularlistDownloadExcel" style="background: blue; color: white">엑셀 다운로드</Button> -->
                    </div>
                </div>

                <DataTable :value="userList" :paginator="false" class="p-datatable-gridlines" :rows="10" dataKey="user_id" :rowHover="true" :loading="loading" responsiveLayout="scroll">
                    <template #empty> 데이터가 없습니다. </template>
                    <template #loading> 데이터를 로드 중입니다. 기다리다. </template>
                    <Column field="slNO" :header="$t('#')">
                        <template #body="{ index }">
                            <span class="p-column-title">slNo</span>

                            {{ query.page * query.record_count - query.record_count + (index + 1) }}
                        </template>
                    </Column>
                    <Column header="아이디" style="min-width: 12rem">
                        <template #body="{ data }">
                            {{ data.email }}
                        </template>
                    </Column>

                    <Column header="품의번호" style="min-width: 16rem">
                        <template #body="{ data }">
                            {{ data.product_no }}
                        </template>
                    </Column>

                    <Column header="계정 승인 요청 일자" style="min-width: 15rem">
                        <template #body="{ data }">
                            <span>
                                {{ data?.create_date ? formatDate(data?.create_date) : '' }}
                            </span>
                        </template>
                    </Column>
                    <Column header="계정 승인 일자" style="min-width: 16rem">
                        <template #body="{ data }">
                            <!-- {{ data.status == 'inactive' ? '-' : formatDate(data.activation_date) }} -->
                                {{ checkStatus(data.status,data.activation_date) }}
                        </template>
                    </Column>

                    <Column header="승인" style="min-width: 6rem">
                        <template #body="{ data }">
                            <div class="p-d-flex">
                                <Button v-if="data.activation_date == null" class="p-mr-2" @click="sendPermission(data.user_id,1)" :loading="permissionLoading" size="small" label="승인"></Button>
                                <Button v-if="data.activation_date == null" size="small" class="p-button-danger" @click="sendPermission(data.user_id,0)" :loading="permissionLoading" label="반송"></Button>
                            </div>
                        </template>
                    </Column>
                </DataTable>

                <div class="p-paginator p-component p-paginator-bottom">
                    <button @click="paginate(1)" :class="{ 'p-disabled': query.page === 1 }" class="p-paginator-first p-paginator-element p-link" type="button" :disabled="query.page === 1">
                        <span class="p-paginator-icon pi pi-angle-double-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(query.page - 1)" :class="{ 'p-disabled': query.page === 1 }" class="p-paginator-next p-paginator-element p-link" type="button" :disabled="query.page === 1">
                        <span class="p-paginator-icon pi pi-angle-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <span class="p-paginator-pages">
                        <button
                            v-for="(page, index) in get_paginator_page(query.total_pages, query.page)"
                            :key="index"
                            @click="paginate(page)"
                            class="p-paginator-page p-paginator-element p-link"
                            :class="{ 'p-highlight': page === query.page }"
                            type="button"
                        >
                            {{ page }}<span class="p-ink"></span>
                        </button>
                    </span>
                    <button @click="paginate(query.page + 1)" :class="{ 'p-disabled': query.page === query.total_pages }" class="p-paginator-next p-paginator-element p-link" type="button" :disabled="query.page === query.total_pages">
                        <span class="p-paginator-icon pi pi-angle-right"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(query.total_pages)" :class="{ 'p-disabled': query.page === query.total_pages }" :disabled="query.page === query.total_pages" class="p-paginator-last p-paginator-element p-link" type="button">
                        <span class="p-paginator-icon pi pi-angle-double-right"></span>
                        <span class="p-ink"></span>
                    </button>
                </div>

                <div style="text-align: right; margin-top: -35px">
                    <label for="order">
                        {{ query.total_records }}개 중 {{ query.page * query.record_count - query.record_count + 1 }} -
                        {{ query.total_pages === query.page ? query.total_records : query.page * query.record_count - query.record_count + 10 }}
                    </label>
                </div>
            </div>
        </div>
        <Dialog v-model:visible="displayModalOne" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true" class="hide-cross">
            <div class="p-field p-fluid">
                <p class="p-text-center">{{ message }}</p>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-center">
                    <Button label="확인" @click="displayModalOne = false" size="large" class="p-button-primary" />
                </div>
            </template>
        </Dialog>
    </div>
</template>


<script setup>
  import { onMounted, ref } from 'vue';
  import utils from '@/service/utils';
  import userService from '@/service/API/SignUpService';
  import moment from 'moment';
  const loading = ref(true);
  const userList = ref([]);
  const message = ref('')
  const displayModalOne = ref(false)
  const get_paginator_page = (total, active) => {
      return utils.paginate(total, active);
  };
  const query = ref({
      record_count: 10,
      page: 1,
      email: '',
      product_no: '',
      total_pages: null,
      total_records: null,
  });
  const getSignUp = async (obj) => {
      loading.value = true;
      try {
          let res = await userService.getSignUpUserList(obj);
          if (res.status == 200) {
              let response = res.data.user_details;
              userList.value = response;
              query.value.total_pages = res.data.total_pages;
              query.value.total_records = res.data.total_records;
              loading.value = false;
          }
      } catch (err) {
          console.log(err);
      }
  };
  onMounted(() => {
      getSignUp(query.value);
  });
  const formatDate = (value) => {
      if (value) {
          return moment(String(value)).locale('ko').format('ll - LTS');
      }
  };
  const search = async () => {
      loading.value = true;
      await getSignUp(query.value);
  };
  const reset = async () => {
      query.value.email = '';
      query.value.product_no = '';
      await getSignUp(query.value);
  };
  const paginate = async (page) => {
      loading.value = true;
      query.value.page = page;
      await getSignUp(query.value);
  };
  const permissionLoading = ref(false)
  const sendPermission = async (userId,status) => {
    permissionLoading.value = true
      let data = {
          user_id: userId,
          status: status,
      };
      try{
        let res = await userService.sendAccess(data)
        if(res.status == 200){
            if(status == 0){
                message.value = '계정 가입 반송 처리되었습니다.'
            }else{
                message.value = '계정 가입 승인 처리되었습니다.'
            }
            await getSignUp(query.value)
            displayModalOne.value = true
            permissionLoading.value = false
        }
      }catch(Err){
        console.log(Err)
        permissionLoading.value = false
      }
  };
  const checkStatus = (status, activationDate) =>{
    if(status == 'deleted' && activationDate){
        return '-'
    }else if(status == 'inactive' && activationDate == null){
        return ''
    }else{
        return formatDate(activationDate)
    }
  }
</script>

<style lang="scss" scoped>
  .p-datatable-tbody {
      .p-button {
          white-space: nowrap;
      }
  }
  .p-fluid {
      .p-button {
          width: auto;
      }
  }

  p#round {
      border-radius: 12px;
      border: 1px solid black;
      width: 52px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
  }

  .highlight:hover {
      border-bottom: 3px solid Blue;
  }
</style>